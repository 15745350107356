import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter, Subscription, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly router = inject(Router);
    private readonly location = inject(Location);

    private readonly subscriptions$ = new Subscription();
    private history: string[] = [];

    constructor() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    tap((event) => {
                        this.history.push(event.urlAfterRedirects);
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    back(): void {
        this.history.pop();

        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl('/');
        }
    }
}
